var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Relief", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add a type of button with edges we change the value of "),
        _c("code", [_vm._v("type")]),
        _vm._v(" by: "),
        _c("code", [_vm._v("relief")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-button", { attrs: { type: "relief" } }, [_vm._v("Primary")]),
          _c("vs-button", { attrs: { color: "success", type: "relief" } }, [
            _vm._v("Success"),
          ]),
          _c("vs-button", { attrs: { color: "danger", type: "relief" } }, [
            _vm._v("Danger"),
          ]),
          _c("vs-button", { attrs: { color: "warning", type: "relief" } }, [
            _vm._v("warning"),
          ]),
          _c("vs-button", { attrs: { color: "dark", type: "relief" } }, [
            _vm._v("dark"),
          ]),
          _c(
            "vs-button",
            { attrs: { color: "rgb(187, 138, 200)", type: "relief" } },
            [_vm._v("Color")]
          ),
          _c("vs-button", { attrs: { disabled: "", type: "relief" } }, [
            _vm._v("Disabled"),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button type="relief">Primary</vs-button>\n<vs-button color="success" type="relief">Success</vs-button>\n<vs-button color="danger" type="relief">Danger</vs-button>\n<vs-button color="warning" type="relief">warning</vs-button>\n<vs-button color="dark" type="relief">dark</vs-button>\n<vs-button color="rgb(187, 138, 200)" type="relief">Color</vs-button>\n<vs-button disabled type="relief">Disabled</vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }