var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Text Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the font color of buttons, need only set the prop "
        ),
        _c("code", [_vm._v("text-color")]),
        _vm._v(" with your color"),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            { attrs: { type: "border", "text-color": "#7367F0" } },
            [_vm._v("Primary")]
          ),
          _c(
            "vs-button",
            { attrs: { type: "border", "text-color": "#28C76F" } },
            [_vm._v("Success")]
          ),
          _c(
            "vs-button",
            { attrs: { type: "border", "text-color": "#EA5455" } },
            [_vm._v("Danger")]
          ),
          _c(
            "vs-button",
            { attrs: { type: "border", "text-color": "rgb(255,159,67)" } },
            [_vm._v("Warning")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "demo-text-dark",
              attrs: { type: "border", "text-color": "rgb(30,30,30)" },
            },
            [_vm._v("Dark")]
          ),
          _c(
            "vs-button",
            { attrs: { type: "border", "text-color": "tomato" } },
            [_vm._v("Color")]
          ),
          _c(
            "vs-button",
            { attrs: { disabled: "", "text-color": "rgb(0, 0, 0)" } },
            [_vm._v("Disabled")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button type="border" text-color="#7367F0">Primary</vs-button>\n<vs-button type="border" text-color="#28C76F">Success</vs-button>\n<vs-button type="border" text-color="#EA5455">Danger</vs-button>\n<vs-button type="border" text-color="rgb(255,159,67)">Warning</vs-button>\n<vs-button type="border" class="demo-text-dark" text-color="rgb(30,30,30)">Dark</vs-button>\n<vs-button type="border" text-color="tomato">Color</vs-button>\n<vs-button disabled text-color="rgb(0, 0, 0)">Disabled</vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }