var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "There are cases in which we need something more personalized like a specific color, you can change the color of the button with the property "
        ),
        _c("code", [_vm._v("color")]),
        _vm._v(" giving it as a value the color"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "If you need a gradient type button, to assign the color you have to use the property "
            ),
            _c("code", [_vm._v("gradient-color-secondary")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colorx,
                expression: "colorx",
              },
            ],
            attrs: { type: "color", name: "", value: "" },
            domProps: { value: _vm.colorx },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colorx = $event.target.value
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colorx2,
                expression: "colorx2",
              },
            ],
            attrs: { type: "color", name: "", value: "" },
            domProps: { value: _vm.colorx2 },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colorx2 = $event.target.value
              },
            },
          }),
          _c("vs-button", { attrs: { color: _vm.colorx, type: "filled" } }, [
            _vm._v("Color"),
          ]),
          _c("vs-button", { attrs: { color: _vm.colorx, type: "border" } }, [
            _vm._v("Color"),
          ]),
          _c("vs-button", { attrs: { color: _vm.colorx, type: "flat" } }, [
            _vm._v("Color"),
          ]),
          _c("vs-button", { attrs: { color: _vm.colorx, type: "line" } }, [
            _vm._v("Color"),
          ]),
          _c(
            "vs-button",
            {
              attrs: {
                color: _vm.colorx,
                "gradient-color-secondary": _vm.colorx2,
                type: "gradient",
              },
            },
            [_vm._v("Color")]
          ),
          _c("vs-button", { attrs: { color: _vm.colorx, type: "relief" } }, [
            _vm._v("Color"),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <div class="">\n    <input type="color" v-model="colorx" name="" value="">\n    <input type="color" v-model="colorx2" name="" value="">\n    <vs-button :color="colorx" type="filled">Color</vs-button>\n    <vs-button :color="colorx" type="border">Color</vs-button>\n    <vs-button :color="colorx" type="flat">Color</vs-button>\n    <vs-button :color="colorx" type="line">Color</vs-button>\n    <vs-button :color="colorx" :gradient-color-secondary="colorx2" type="gradient">Color</vs-button>\n    <vs-button :color="colorx"  type="relief">Color</vs-button>\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      colorx:\'#9ecc38\',\n      colorx2:\'#3EC9D6\'\n    }\n  },\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }