var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Router", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can send a string or object to directive to. This directive wrap a $router.push() vue method, you can use all programmatic navigation on vue router."
        ),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: {
                color: "primary",
                type: "filled",
                to: "/components/list",
              },
            },
            [_vm._v("String literal")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                color: "warning",
                type: "filled",
                to: { path: "/components/list" },
              },
            },
            [_vm._v("Object Path")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "success", type: "filled", to: { name: "todo" } },
            },
            [_vm._v("Named Router")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                color: "dark",
                type: "filled",
                to: { path: "/pages/search", query: { q: "modern admin" } },
              },
            },
            [_vm._v("With Query")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button color="primary" type="filled" to="/components/list">String literal</vs-button>\n<vs-button color="warning" type="filled" :to="{ path: \'/components/list\' }">Object Path</vs-button>\n<vs-button color="success" type="filled" :to="{ name: \'todo\'}">Named Router</vs-button>\n<vs-button color="dark"    type="filled" :to="{ path: \'/pages/search\', query: { q: \'modern admin\' } }">With Query</vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }