var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Border", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add a type of button with edges we change the value of "),
        _c("code", [_vm._v("border")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-button", { attrs: { color: "primary", type: "border" } }, [
            _vm._v("Primary"),
          ]),
          _c("vs-button", { attrs: { color: "success", type: "border" } }, [
            _vm._v("Success"),
          ]),
          _c("vs-button", { attrs: { color: "danger", type: "border" } }, [
            _vm._v("Danger"),
          ]),
          _c("vs-button", { attrs: { color: "warning", type: "border" } }, [
            _vm._v("Warning"),
          ]),
          _c("vs-button", { attrs: { color: "dark", type: "border" } }, [
            _vm._v("Dark"),
          ]),
          _c(
            "vs-button",
            { attrs: { color: "rgb(11, 189, 135)", type: "border" } },
            [_vm._v("RGB")]
          ),
          _c("vs-button", { attrs: { disabled: "", type: "border" } }, [
            _vm._v("Disabled"),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button color="primary" type="border">Primary</vs-button>\n<vs-button color="success" type="border">Success</vs-button>\n<vs-button color="danger" type="border">Danger</vs-button>\n<vs-button color="warning" type="border">Warning</vs-button>\n<vs-button color="dark" type="border">Dark</vs-button>\n<vs-button color="rgb(11, 189, 135)" type="border">RGB</vs-button>\n<vs-button disabled type="border">Disabled</vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }