var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "button-demo" } },
    [
      _c("button-filled"),
      _c("button-border"),
      _c("button-flat"),
      _c("button-line-down"),
      _c("button-gradient"),
      _c("button-relief"),
      _c("button-color"),
      _c("button-text-color"),
      _c("button-icon"),
      _c("button-icon-only"),
      _c("button-size"),
      _c("button-router"),
      _c("button-link"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }