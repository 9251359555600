var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Line Down", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add a type of button with edges we change the value of "),
        _c("code", [_vm._v("type")]),
        _vm._v(" by: "),
        _c("code", [_vm._v("line")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              'you can change the position of the line up with the property line-position="top". You can also change the way the line appears on mouse hover with the property line-origin using the allowed values: '
            ),
            _c("strong", [_vm._v("left, center(default) or right")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-button", { attrs: { type: "line" } }, [_vm._v("Primary")]),
          _c(
            "vs-button",
            {
              attrs: { type: "line", "line-origin": "left", color: "success" },
            },
            [_vm._v("Success")]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "line", "line-origin": "right", color: "danger" },
            },
            [_vm._v("Danger")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                type: "line",
                "line-position": "top",
                "line-origin": "left",
                color: "warning",
              },
            },
            [_vm._v("Warning")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                type: "line",
                "line-position": "top",
                "line-origin": "right",
                color: "dark",
              },
            },
            [_vm._v("Dark")]
          ),
          _c("vs-button", { attrs: { type: "line", disabled: "" } }, [
            _vm._v("Disabled"),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button type="line">Primary</vs-button>\n<vs-button type="line" line-origin="left" color="success">Success</vs-button>\n<vs-button type="line" line-origin="right" color="danger">Danger</vs-button>\n<vs-button type="line" line-position="top" line-origin="left" color="warning">Warning</vs-button>\n<vs-button type="line" line-position="top" line-origin="right" color="dark">Dark</vs-button>\n<vs-button type="line" disabled>Disabled</vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }