var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Link", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can specify for "),
        _c("code", [_vm._v("window.location.href")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v("You can use the "),
            _c("code", [_vm._v("target")]),
            _vm._v(" property to window.open()"),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: {
                color: "primary",
                type: "filled",
                href: "https://pixinvent.com/",
              },
            },
            [_vm._v("String literal")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                color: "primary",
                type: "filled",
                href: { url: "https://pixinvent.com/" },
              },
            },
            [_vm._v("Object Path")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                color: "primary",
                type: "filled",
                target: "",
                href: "https://pixinvent.com/",
              },
            },
            [_vm._v("Open New Tab")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button color="primary" type="filled" href="https://pixinvent.com/">String literal</vs-button>\n<vs-button color="primary" type="filled" :href="{url: \'https://pixinvent.com/\'}">Object Path</vs-button>\n<vs-button color="primary" type="filled" target href="https://pixinvent.com/">Open New Tab</vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }