var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can add an icon to the button with the property "),
        _c("code", [_vm._v("icon")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "Vuesax uses the Google Material Icons font library by default. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page")]
            ),
            _vm._v(
              ". Other icon libraries can be used by providing the class for the respective pack in the icon-pack property. ex. FA4 uses fa or fas, FA5 uses fas, far, or fal."
            ),
          ]),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5 mb-3",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "You can change the position of the icon so that it is after the text with the property "
            ),
            _c("code", [_vm._v("icon-after")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: {
                color: "primary",
                type: "border",
                "icon-pack": "feather",
                icon: "icon-home",
              },
            },
            [_vm._v("Home")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                color: "warning",
                type: "filled",
                "icon-pack": "feather",
                icon: "icon-star",
              },
            },
            [_vm._v("Star")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                color: "success",
                type: "flat",
                "icon-pack": "feather",
                icon: "icon-check",
              },
            },
            [_vm._v("Done")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                color: "dark",
                type: "line",
                "icon-pack": "feather",
                icon: "icon-menu",
                "icon-after": "",
              },
            },
            [_vm._v("menu")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                color: "danger",
                type: "gradient",
                "icon-pack": "feather",
                icon: "icon-heart",
                "icon-after": "",
              },
            },
            [_vm._v("favorite")]
          ),
          _c(
            "vs-button",
            {
              attrs: {
                disabled: "",
                color: "primary",
                type: "border",
                "icon-pack": "feather",
                icon: "icon-home",
              },
            },
            [_vm._v("Disabled")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button color="primary" type="border" icon-pack="feather" icon="icon-home">Home</vs-button>\n<vs-button color="warning" type="filled" icon-pack="feather" icon="icon-star">Star</vs-button>\n<vs-button color="success" type="flat" icon-pack="feather" icon="icon-check">Done</vs-button>\n<vs-button color="dark" type="line" icon-pack="feather" icon="icon-menu" icon-after>menu</vs-button>\n<vs-button color="danger" type="gradient" icon-pack="feather" icon="icon-heart" icon-after>favorite</vs-button>\n<vs-button disabled color="primary" type="border" icon-pack="feather" icon="icon-home">Disabled</vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }