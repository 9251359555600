var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon Only", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can have a button with only the icon you want with the property "
        ),
        _c("code", [_vm._v("icon")]),
        _vm._v(" and not add any internal value to the button"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5 mb-3",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "You can use the radius property to determine the border-radius of the button, there are many cases in which we need it to be round for example."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-button", {
            attrs: {
              color: "primary",
              type: "border",
              "icon-pack": "feather",
              icon: "icon-search",
            },
          }),
          _c("vs-button", {
            attrs: {
              color: "warning",
              type: "filled",
              "icon-pack": "feather",
              icon: "icon-archive",
            },
          }),
          _c("vs-button", {
            attrs: {
              color: "success",
              type: "flat",
              "icon-pack": "feather",
              icon: "icon-camera",
            },
          }),
          _c("vs-button", {
            attrs: {
              color: "dark",
              type: "line",
              "icon-pack": "feather",
              icon: "icon-calendar",
            },
          }),
          _c("vs-button", {
            attrs: {
              color: "danger",
              type: "gradient",
              "icon-pack": "feather",
              icon: "icon-user-plus",
            },
          }),
          _c("vs-button", {
            attrs: {
              disabled: "",
              color: "primary",
              type: "border",
              "icon-pack": "feather",
              icon: "icon-search",
            },
          }),
          _c("vs-button", {
            attrs: {
              radius: "",
              color: "primary",
              type: "border",
              "icon-pack": "feather",
              icon: "icon-search",
            },
          }),
          _c("vs-button", {
            attrs: {
              radius: "",
              color: "warning",
              type: "filled",
              "icon-pack": "feather",
              icon: "icon-archive",
            },
          }),
          _c("vs-button", {
            attrs: {
              radius: "",
              color: "success",
              type: "flat",
              "icon-pack": "feather",
              icon: "icon-camera",
            },
          }),
          _c("vs-button", {
            attrs: {
              radius: "",
              color: "dark",
              type: "line",
              "icon-pack": "feather",
              icon: "icon-calendar",
            },
          }),
          _c("vs-button", {
            attrs: {
              radius: "",
              color: "danger",
              type: "gradient",
              "icon-pack": "feather",
              icon: "icon-user-plus",
            },
          }),
          _c("vs-button", {
            attrs: {
              disabled: "",
              radius: "",
              color: "primary",
              type: "border",
              "icon-pack": "feather",
              icon: "icon-search",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button color="primary" type="border" icon-pack="feather" icon="icon-search"></vs-button>\n<vs-button color="warning" type="filled" icon-pack="feather" icon="icon-archive"></vs-button>\n<vs-button color="success" type="flat" icon-pack="feather" icon="icon-camera"></vs-button>\n<vs-button color="dark" type="line" icon-pack="feather" icon="icon-calendar"></vs-button>\n<vs-button color="danger" type="gradient" icon-pack="feather" icon="icon-user-plus"></vs-button>\n<vs-button disabled color="primary" type="border" icon-pack="feather" icon="icon-search"></vs-button>\n\n<vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-search"></vs-button>\n<vs-button radius color="warning" type="filled" icon-pack="feather" icon="icon-archive"></vs-button>\n<vs-button radius color="success" type="flat" icon-pack="feather" icon="icon-camera"></vs-button>\n<vs-button radius color="dark" type="line" icon-pack="feather" icon="icon-calendar"></vs-button>\n<vs-button radius color="danger" type="gradient" icon-pack="feather" icon="icon-user-plus"></vs-button>\n<vs-button disabled radius color="primary" type="border" icon-pack="feather" icon="icon-search"></vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }