var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Size", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To define the size of button the directive is used "),
        _c("code", [_vm._v("size")]),
        _vm._v(". there are three type of size: "),
        _c("code", [_vm._v("large")]),
        _vm._v(", "),
        _c("code", [_vm._v("default")]),
        _vm._v(", "),
        _c("code", [_vm._v("small")]),
        _vm._v(
          ". It is not important to specify the size when the button is of type "
        ),
        _c("code", [_vm._v("default")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-button", { attrs: { size: "large" } }, [_vm._v("Large")]),
          _c("vs-button", [_vm._v("Default")]),
          _c("vs-button", { attrs: { size: "small" } }, [_vm._v("Small")]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button size="large">Large</vs-button>\n<vs-button>Default</vs-button>\n<vs-button size="small">Small</vs-button>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }